/* eslint-disable */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import queryString from 'query-string';

import { PAGES } from 'config';
import { GET_BOOKING } from 'actions/booking';
import { mapStateToProps } from 'utilities';
import { BookingLayout } from 'containers';
import { BookingHeader } from 'layouts';

class BookingsPaymentSuccessPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      balanceRemaining: 0,
      totalPaid: 0,
      bookingTitle: '',
      bookingSubTitle: '',
    };
  }

  componentDidMount() {
    const { booking } = this.props;
    if (booking.currentBooking) {
      this.updateBookingPayment();
    }
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;
    // if we have now got a booking
    if (!prevProps.booking.currentBooking && booking.currentBooking) {
      this.updateBookingPayment();
    }
  }

  trackFacebookPurchaseEvent() {
    const { booking } = this.props;
    fbq('trackSingle', '2027634157481410', 'Purchase', {
     content_type: 'destination',
     travel_start: booking.currentBooking.departure_date,
     travel_end: booking.currentBooking.end_date,
     content_ids: booking.currentBooking.tour_id,
     city: booking.currentBooking.links.fb_pixel_city,
     region: booking.currentBooking.links.fb_pixel_region,
     country: 'New Zealand',
     num_adults: booking.currentBooking.traveller_ids.length,
     value: booking.currentBooking.tour_price,
     currency: 'NZD'
   });
  }

  trackNewZealandDotComPurchaseEvent() {
    const { booking } = this.props;
    fbq('trackSingle', '632522023999943', 'Purchase', {
     content_type: 'destination',
     content_ids: '5519924',
     city: booking.currentBooking.links.fb_pixel_city,
     region: booking.currentBooking.links.fb_pixel_region,
     country: 'New Zealand',
     value: booking.currentBooking.tour_price,
     currency: 'NZD'
   });
  }

  /**
   * Update payment with details from PX Pay
   */
  updateBookingPayment() {
    const { user, booking, dispatch } = this.props;
    const PARSED = queryString.parse(window.location.search);

    if (PARSED.status) {
      if (PARSED.status === 'success') {
        this.setState({
          bookingTitle: 'Payment Success',
          bookingSubTitle: "Wahoo! Your payment is complete. We'll send you an email shortly with all the information.",
        });
      } else {
        this.setState({
          bookingTitle: 'Payment Error',
          bookingSubTitle: "You're previous payment failed, your credit card was not charged.",
        });
      }
      (async () => {
        // Tracking ecommerce on first payment
        if (PARSED.count && PARSED.count < 2) {
          this.track();
          if (typeof fbq !== 'undefined') {
            this.trackFacebookPurchaseEvent();
            this.trackNewZealandDotComPurchaseEvent();
          }
        }
        // 2. fetch new booking data
        await GET_BOOKING(dispatch, booking.currentBooking.id, user.session.token, user.session.email);
      })();
    }
  }

  track() {
    const { booking } = this.props;
    window.dataLayer = window.dataLayer || [];

    const arrProducts = [];

    arrProducts.push({
      sku: booking.currentBooking.tour_id,
      name: booking.currentBooking.tour_name,
      category: 'Tours',
      price: booking.currentBooking.tour_price,
      quantity: booking.currentBooking.traveller_ids.length,
    });

    const groupBy = (items, key) => items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [
          ...(result[item[key]] || []),
          item,
        ],
      }),
      {},
    );

    if (booking.addOns.length > 0) {
      const addonsList = groupBy(booking.addOns, 'name');

      Object.keys(addonsList).map(key => arrProducts.push({
        sku: addonsList[key][0].db_addon_id,
        name: key,
        category: 'Add-ons',
        price: addonsList[key][0].price,
        quantity: addonsList[key].length,
      }));
    }

    booking.accommodations.map(accomm => arrProducts.push({
      sku: accomm.db_accomm_id,
      name: accomm.is_before ? `Pre tour Accomm - ${accomm.type}` : `Post tour Accomm - ${accomm.type}`,
      category: 'Pre & Post Extras',
      price: accomm.price,
      quantity: 1,
    }));

    window.dataLayer.push({
      event: 'transaction',
      transactionId: booking.currentBooking.id,
      transactionTotal: booking.currentBooking.total,
      transactionProducts: arrProducts,
    });
  }

  render() {
    const { booking } = this.props;
    const {
      balanceRemaining,
      totalPaid,
      bookingTitle,
      bookingSubTitle,
    } = this.state;
    if (booking.currentBooking) {
      this.setState({
        balanceRemaining: booking.currentBooking.balance_remaining,
        totalPaid: booking.currentBooking.total_paid,
      });
    }

    return (
      <BookingLayout page="bookings/payments/success">
        <div className="l-two-col">
          <BookingHeader
            title={bookingTitle}
            subTitle={bookingSubTitle}
          />
          <div className="l-booking-payments l-booking-payments--success">
            {
              booking.currentBooking
                ? (
                  <>
                    <div className="l-booking-payments__history">
                      <table>
                        <thead>
                          <tr>
                            <th>You Paid:</th>
                            <th>Remaining Balance:</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h5 className="c-heading c-heading--h4">${totalPaid}</h5>
                            </td>
                            <td>
                              <h5 className="c-heading c-heading--h4">${balanceRemaining}</h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Link to={PAGES.USER.PATH} className="c-button c-button--wide c-button--primary">Back to my account</Link>
                  </>
                ) : <h4>Loading...</h4>
            }
          </div>
        </div>
      </BookingLayout>
    );
  }
}

BookingsPaymentSuccessPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BookingsPaymentSuccessPage);
